[data-v-3e430aaf] .el-transfer-panel {
  width: 30vw;
  height: 100%;
}
[data-v-3e430aaf] .el-transfer-panel__body {
  height: calc(100% - 55px);
}
[data-v-3e430aaf] .el-transfer-panel__list {
  height: calc(100% - 47px) !important;
}
[data-v-3e430aaf] .el-checkbox {
  margin-left: 0px !important;
}
.examPaper[data-v-3e430aaf] .el-table {
  height: 392px !important;
}
.examPaper[data-v-3e430aaf] .el-table__body-wrapper {
  max-height: 358px !important;
  overflow-y: auto;
}
.examPaper[data-v-3e430aaf] .el-dialog__body {
  margin-bottom: 0 !important;
}